import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronUp, FaChevronDown, FaEye, FaUndo } from 'react-icons/fa';

function FloatingEstimationArea({
  scale,
  isRangeEstimation,
  selectedLowBet,
  selectedHighBet,
  onLowBetSelect,
  onHighBetSelect,
  isRevealed,
  onReveal,
  onReset,
  resetTrigger
}) {
  const { t } = useTranslation();
  const [localLowBet, setLocalLowBet] = useState(null);
  const [localHighBet, setLocalHighBet] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    setLocalLowBet(null);
    setLocalHighBet(null);
  }, [resetTrigger, isRevealed]);

  useEffect(() => {
    setLocalLowBet(selectedLowBet);
    setLocalHighBet(selectedHighBet);
  }, [selectedLowBet, selectedHighBet]);

  const scales = {
    fibonacci: [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, '☕'],
    modifiedFibonacci: [0,1,2,3,5,8,13,21,40,80,100, '☕'],
    tShirt: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', '☕'],
    progression: [0, 1, 2, 4, 6, 8, 16, 32, 64, '☕'],
    linear: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, '☕'],
    largeUncertainSmall: ['Groß', 'Unsicher', 'Klein', '☕'],
  };

  const scaleValues = scales[scale] || [];

  const handleBetSelect = (bet, isLowBet) => {
    if (isRevealed) return;

    if (isLowBet) {
      const newLowBet = bet === localLowBet ? null : bet;
      setLocalLowBet(newLowBet);
      onLowBetSelect(newLowBet);
    } else {
      const newHighBet = bet === localHighBet ? null : bet;
      setLocalHighBet(newHighBet);
      onHighBetSelect(newHighBet);
    }
  };

  const handleLocalReset = () => {
    setLocalLowBet(null);
    setLocalHighBet(null);
    setIsExpanded(true);
    onReset();
  };

  const renderBetButton = (bet, isLowBet) => {
    const isSelected = isLowBet ? localLowBet === bet : localHighBet === bet;

    return (
      <button
        key={bet}
        className={`px-3 py-1 rounded-lg text-sm font-medium transition-all duration-200 ease-in-out ${
          isSelected && !isRevealed
            ? 'bg-blue-500 text-white'
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        } ${isRevealed ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={() => handleBetSelect(bet, isLowBet)}
        disabled={isRevealed}
      >
        {bet}
      </button>
    );
  };

  return (
    <AnimatePresence>
      {isExpanded ? (
        <motion.div
          key="expanded"
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className="fixed bottom-0 left-0 right-0 bg-white shadow-xl z-[1000] overflow-hidden"
        >
          <div className="p-4">
            <button
              onClick={() => setIsExpanded(false)}
              className="absolute top-4 right-4 bg-blue-500 text-white p-2 rounded-full z-10"
            >
              <FaChevronDown />
            </button>
            <div className={`grid ${isRangeEstimation ? 'grid-cols-2' : 'grid-cols-1'} gap-4 mb-4`}>
              <div>
                <h3 className="text-xl font-semibold mb-2 text-center">
                  {isRangeEstimation ? t('Low Estimate') : t('Estimate')}
                </h3>
                <div className="flex flex-wrap gap-2 justify-center">
                  {scales[scale]?.map(bet => renderBetButton(bet, true))}
                </div>
              </div>
              {isRangeEstimation && (
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-center">{t('High Estimate')}</h3>
                  <div className="flex flex-wrap gap-2 justify-center">
                    {scales[scale]?.map(bet => renderBetButton(bet, false))}
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-center space-x-4">
              <button
                onClick={onReveal}
                disabled={isRevealed}
                className={`flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ease-in-out ${
                  !isRevealed
                    ? 'bg-blue-500 hover:bg-blue-600 text-white'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                <FaEye className="mr-2" /> {t('Reveal Estimates')}
              </button>
              <button
                onClick={handleLocalReset}
                disabled={!isRevealed}
                className={`flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 ease-in-out ${
                  isRevealed
                    ? 'bg-red-500 hover:bg-red-600 text-white'
                    : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
              >
                <FaUndo className="mr-2" /> {t('Reset')}
              </button>
            </div>
          </div>
        </motion.div>
      ) : (
        <motion.div
          key="collapsed"
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          exit={{ y: 100 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className="fixed bottom-0 left-1/2 transform -translate-x-1/2 z-[1000]"
        >
          <motion.button
            onClick={() => setIsExpanded(true)}
            className="bg-blue-500 text-white py-2 px-4 rounded-t-full flex items-center justify-center shadow-lg"
          >
            <FaChevronUp className="w-4 h-4 mr-2" />
            <span className="text-sm font-semibold">{t('Estimate')}</span>
          </motion.button>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default FloatingEstimationArea;
