import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus, FaTrash, FaEdit, FaChevronLeft, FaChevronRight, FaStar, FaCheck, FaTimes } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../firebase';

function EnhancedStoryList({ sessionId, stories, scale, onStorySelect, currentStory, onExpand }) {
  const [newStory, setNewStory] = useState('');
  const [editingStory, setEditingStory] = useState(null);
  const [editingTitle, setEditingTitle] = useState('');
  const [isExpanded, setIsExpanded] = useState(true);
  const [showStats, setShowStats] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    onExpand(isExpanded);
  }, [isExpanded, onExpand]);

  const addStory = async () => {
    if (newStory.trim()) {
      const storyToAdd = { 
        id: `story_${Date.now()}`, 
        title: newStory.trim(), 
        estimate: null,
        storyPoints: null 
      };
      try {
        await updateDoc(doc(db, 'sessions', sessionId), {
          stories: arrayUnion(storyToAdd)
        });
        setNewStory('');
      } catch (error) {
        console.error('Error adding story:', error);
      }
    }
  };

  const updateStoryPoints = async (storyId, points) => {
    const updatedStories = stories.map(story => 
      story.id === storyId ? { ...story, storyPoints: points } : story
    );
    try {
      await updateDoc(doc(db, 'sessions', sessionId), { stories: updatedStories });
    } catch (error) {
      console.error('Error updating story points:', error);
    }
  };

  const removeStory = async (storyId) => {
    const storyToRemove = stories.find(story => story.id === storyId);
    try {
      await updateDoc(doc(db, 'sessions', sessionId), {
        stories: arrayRemove(storyToRemove)
      });
    } catch (error) {
      console.error('Error removing story:', error);
    }
  };

  const startEditing = (story) => {
    setEditingStory(story.id);
    setEditingTitle(story.title);
  };

  const handleUpdateStory = async (storyId) => {
    if (editingTitle.trim()) {
      try {
        const updatedStories = stories.map(story => 
          story.id === storyId ? { ...story, title: editingTitle.trim() } : story
        );
        await updateDoc(doc(db, 'sessions', sessionId), { stories: updatedStories });
        setEditingStory(null);
        setEditingTitle('');
      } catch (error) {
        console.error('Error updating story:', error);
      }
    }
  };

  const cancelEditing = () => {
    setEditingStory(null);
    setEditingTitle('');
  };

  const calculateAveragePoints = (story) => {
    if (!story.estimates || story.estimates.length === 0) return null;
    const sum = story.estimates.reduce((acc, est) => acc + est, 0);
    return (sum / story.estimates.length).toFixed(1);
  };

  return (
    <AnimatePresence>
      {isExpanded ? (
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: '384px', opacity: 1 }}
          exit={{ width: 0, opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed top-20 right-0 bottom-0 bg-white shadow-lg z-50 flex flex-col rounded-l-xl"
          style={{ maxHeight: 'calc(100vh - 5rem)', marginTop: '1rem' }}
        >
          <div className="p-4 flex flex-col h-full">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-bold text-gray-800">{t('Stories')}</h2>
              <button
                onClick={() => setIsExpanded(false)}
                className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors"
              >
                <FaChevronRight className="w-4 h-4" />
              </button>
            </div>
            <div className="mb-4">
              <div className="flex">
                <input
                  type="text"
                  placeholder={t('Enter new story or paste Jira link')}
                  value={newStory}
                  onChange={(e) => setNewStory(e.target.value)}
                  className="flex-grow px-4 py-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  onClick={addStory}
                  className="bg-blue-500 text-white px-4 py-2 rounded-r-lg hover:bg-blue-600 transition-colors"
                >
                  <FaPlus />
                </button>
              </div>
            </div>
            <div className="flex-grow overflow-y-auto space-y-2 pr-2">
              {stories.map((story) => (
                <motion.div
                  key={story.id}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className={`p-4 rounded-lg transition-colors duration-200 ${
                    currentStory && currentStory.id === story.id 
                      ? 'bg-blue-50 border border-blue-200' 
                      : 'bg-gray-50 hover:bg-gray-100'
                  }`}
                >
                  {editingStory === story.id ? (
                    <div className="flex items-center space-x-2">
                      <input
                        type="text"
                        value={editingTitle}
                        onChange={(e) => setEditingTitle(e.target.value)}
                        className="flex-grow px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        autoFocus
                      />
                      <button
                        onClick={() => handleUpdateStory(story.id)}
                        className="text-green-500 hover:text-green-700 p-1 rounded"
                      >
                        <FaCheck size={16} />
                      </button>
                      <button
                        onClick={cancelEditing}
                        className="text-red-500 hover:text-red-700 p-1 rounded"
                      >
                        <FaTimes size={16} />
                      </button>
                    </div>
                  ) : (
                    <div className="space-y-2">
                      <div className="flex items-center justify-between">
                        <span
                          className="flex-grow cursor-pointer font-medium text-gray-700"
                          onClick={() => onStorySelect(story)}
                        >
                          {story.title}
                        </span>
                        <div className="flex items-center space-x-2">
                          <button
                            onClick={() => startEditing(story)}
                            className="text-blue-500 hover:text-blue-700 p-1 rounded"
                          >
                            <FaEdit size={16} />
                          </button>
                          <button
                            onClick={() => removeStory(story.id)}
                            className="text-red-500 hover:text-red-700 p-1 rounded"
                          >
                            <FaTrash size={16} />
                          </button>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2 mt-2 p-2 bg-white rounded-lg border border-gray-200">
                        <FaStar className="text-yellow-400" size={16} />
                        <select
                          value={story.storyPoints || ''}
                          onChange={(e) => updateStoryPoints(story.id, e.target.value)}
                          className="flex-grow bg-transparent border-0 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-sm"
                        >
                          <option value="">{t('Story Points')}</option>
                          {scale.map((value) => (
                            <option key={value} value={value}>{value}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
            {showStats && (
              <div className="mt-4 p-4 bg-gray-50 rounded-lg">
                <h3 className="text-lg font-semibold mb-2">{t('Session Statistics')}</h3>
                <div className="space-y-2">
                  <p>Stories Estimated: {stories.filter(s => s.storyPoints).length}/{stories.length}</p>
                  <p>Average Story Points: {calculateAveragePoints(stories)}</p>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      ) : (
        <motion.div
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: '48px', opacity: 1 }}
          exit={{ width: 0, opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          className="fixed top-20 right-0 bottom-0 bg-white shadow-lg z-50 flex flex-col items-center rounded-l-xl"
          style={{ maxHeight: 'calc(100vh - 5rem)' }}
        >
          <button
            onClick={() => setIsExpanded(true)}
            className="mt-4 bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition-colors"
          >
            <FaChevronLeft className="w-4 h-4" />
          </button>
          <div className="flex flex-col items-center justify-center h-full">
            <span className="transform -rotate-90 text-sm font-semibold text-gray-700 whitespace-nowrap">
              {t('Stories')}
            </span>
          </div>
          <div className="absolute bottom-4 left-0 right-0">
            <div className="flex flex-col items-center space-y-2">
              {stories.slice(0, 3).map((story, index) => (
                <div
                  key={story.id}
                  className={`w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium ${
                    currentStory && currentStory.id === story.id ? 'ring-2 ring-blue-500' : ''
                  }`}
                  onClick={() => onStorySelect(story)}
                >
                  {index + 1}
                </div>
              ))}
              {stories.length > 3 && (
                <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-xs font-medium">
                  +{stories.length - 3}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default EnhancedStoryList;
