import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useTranslation } from 'react-i18next';
import SessionSetup from './SessionSetup';
import { motion } from 'framer-motion';

function CreateSession({ onClose, onSessionCreated }) {
  const [sessionName, setSessionName] = useState('');
  const [scale, setScale] = useState('');
  const [isRangeEstimation, setIsRangeEstimation] = useState(false);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleScaleSelect = (selectedScale) => {
    setScale(selectedScale);
    setIsSetupComplete(true);
  };

  const handleCreateSession = async (e) => {
    e.preventDefault();
    if (!sessionName.trim() || !scale) return;

    setIsLoading(true);
    try {
      const docRef = await addDoc(collection(db, 'sessions'), {
        name: sessionName,
        scale: scale,
        isRangeEstimation: isRangeEstimation,
        createdAt: new Date(),
        isRevealed: false,
        stories: [],
        emojiReactions: {} // Add this line
      });
      if (onSessionCreated) {
        onSessionCreated(docRef.id);
      }
    } catch (error) {
      console.error('Error creating session:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        className="bg-white rounded-lg shadow-xl w-full max-w-4xl overflow-hidden"
      >
        <div className="p-6 sm:p-8">
          <h2 className="text-3xl font-bold mb-6 text-gray-800 text-center">{t('Create New Session')}</h2>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
              <span className="block sm:inline">{error}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3" onClick={() => setError(null)}>
                <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                </svg>
              </span>
            </div>
          )}
          
          {!isSetupComplete ? (
            <SessionSetup
              handleScaleSelect={handleScaleSelect}
              isRangeEstimation={isRangeEstimation}
              setIsRangeEstimation={setIsRangeEstimation}
            />
          ) : (
            <form onSubmit={handleCreateSession} className="space-y-6">
              <div>
                <label htmlFor="sessionName" className="block text-sm font-medium text-gray-700 mb-1">
                  {t('Session Name')}
                </label>
                <input
                  id="sessionName"
                  type="text"
                  placeholder={t('Enter session name')}
                  value={sessionName}
                  onChange={(e) => setSessionName(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex items-center justify-between space-x-4">
                {onClose && (
                  <button
                    type="button"
                    onClick={onClose}
                    className="flex-1 py-2 px-4 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition duration-300 ease-in-out"
                  >
                    {t('Cancel')}
                  </button>
                )}
                <button
                  type="submit"
                  className={`flex-1 py-2 px-4 rounded-lg font-bold ${
                    isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 text-white'
                  } transition duration-300 ease-in-out`}
                  disabled={isLoading}
                >
                  {isLoading ? t('Creating...') : t('Create Session')}
                </button>
              </div>
            </form>
          )}
        </div>
      </motion.div>
    </motion.div>
  );
}

export default CreateSession;